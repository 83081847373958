<template>
    <BaseModal @close="$emit('close')" cardWidth="490px" noPadding noToolbarPadding noExit>
        <template #toolbar>
            <div class="max-w-xs">
                <BaseText type="label">
                    Choose a folder for your duplicate
                </BaseText>
                <BaseText size="sm" class="mt-1 text-text-normal">
                    Select a folder to save your duplicated board and start working with it.
                </BaseText>
            </div>
        </template>
        <template #default>
            <div class="w-full flex flex-col items-stretch">
                <!-- Folder dropdown -->
                <div class="w-full h-full p-5 border-t border-b mt-5">
                    <FolderDropdown @change="(val) => selectedFolder = val" :selectedFolder.sync="selectedFolder" />
                </div>
                <div class="w-full flex justify-between items-center p-5">
                    <BaseButton @click="$emit('close')" class="px-2 py-1.5 rounded-md transition-colors hover:bg-neutral-25">
                        Cancel
                    </BaseButton>
                    <BaseButton outlined @click="duplicateBoard" :loading="loading">
                        <div class="flex gap-2 items-center">
                            <CopyBoardIcon color="#5E6678" />
                            <BaseText type="label" size="sm">Duplicate Board</BaseText>
                        </div>
                    </BaseButton>
                </div>
            </div>
        </template>
    </BaseModal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import FirebaseAPI from '@/api/firebase'
import BaseButton from '../globals/BaseButton.vue';
// Icons
import CopyBoardIcon from '../globals/Icons/CopyBoardIcon.vue';
import FolderDropdown from './FolderDropdown.vue';


export default {
    name: 'DuplicateBoardModal',
    components: {
        BaseButton,
        CopyBoardIcon,
        FolderDropdown
    },
    props: {
        currentBoard: {
            type: Object
        },
        ads: {
            type: Array
        },
        currentTeam: {
            type: Object
        }
    },
    data() {
        return {
            selectedFolder: { id: null, name: 'Default Folder' },
            loading: false
        }
    },
    computed: {
        ...mapGetters('BoardsModule', ['getFolders']),
    },
    methods: {
        ...mapActions('BoardsModule', ['fetchBoards', 'fetchFolders']),
        async duplicateBoard() {
            try {
                this.loading = true
                const ads = this.$props.ads;
                const currentBoard = this.$props.currentBoard;

                const currentTeam = this.$props.currentTeam;
                const boardPayload = {
                    ads: [],
                    description: `This board was copied from ${currentBoard?.name || 'a foreplay user'}`,
                    name: `#Copy of ${currentBoard.name
                        .replace(/ /g, '_')
                        .toLowerCase()
                        .trim()}`,
                    ...(currentTeam && { teamId: currentTeam.id })
                }
                const boardId = await FirebaseAPI.Boards.create(boardPayload, this.selectedFolder.id ? this.selectedFolder : null)

                await Promise.all(ads.map(ad =>
                    FirebaseAPI.Advertisements.save({
                        ...ad,
                        board_ids: [boardId],
                        createdAt: +new Date()
                    }, currentTeam.id, false)
                ));
                this.loading = false
                await this.fetchBoards()
                await this.fetchFolders()
                this.$emit('close')

                this.$showAlert({
                    message: 'Board duplicated successfully',
                    type: 'success'
                })

            } catch (e) {
                console.log(e)
                this.$showAlert({
                    message: 'Error duplicating board',
                    type: 'error'
                })
            }
        }
    }
}
</script>