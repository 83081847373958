<template>
  <div class="w-full h-full">
    <!-- Dupe Modal -->
    <DuplicateBoardModal v-if="showDuplicateModal" :currentBoard="board" :ads="ads" @close="showDuplicateModal = false"
      :currentTeam="getTeam" />
    <!-- Gradient -->
    <div class="absolute top-0 right-0 w-full h-full">
      <div class="w-full h-full relative z-0">
        <WhiteLabelGradient :fill="boardTeam?.whiteLabel?.companyColor" />
      </div>
    </div>

    <div class="flex whitespace-nowrap justify-between text-white pt-4 px-7 relative" style="z-index: 100;">
      <div class="flex items-center gap-2">
        <div v-if="!showDefault && boardTeam?.whiteLabel" class="flex items-center gap-2"
          :style="{ cursor: boardTeam?.whiteLabel?.companyURL ? 'pointer' : 'auto' }" @click.prevent="openCompanyURL">
          <img :src="boardTeam?.avatar || fallbackAvatar" class="rounded-md w-5 h-5">
          <div class="flex gap-1">
            <BaseText type="label" size="lg">
              {{ boardTeam?.whiteLabel?.companyName }}
            </BaseText>
          </div>
        </div>
        <div v-if="!showDefault && boardTeam?.whiteLabel" class="text-neutral-alpha-300 w-min text-center mx-2">
          |
        </div>
        <img v-if="sharedBy?.avatar" :src="sharedBy.avatar" class="rounded-full w-5 h-5">
        <img v-else src="../../../assets/images/defaultProfileImage.webp" class="rounded-full w-5 h-5">
        <BaseText v-if="sharedBy?.name || $route.query?.user" type="label" size="sm">
          {{ sharedBy?.name || $route.query?.user }}
        </BaseText>
        <BaseText v-else-if="sharedBy?.first_name" type="label" size="sm" class="select-none">
          {{ sharedBy.first_name }}&nbsp;{{ sharedBy?.last_name }}
        </BaseText>
        <BaseText v-else-if="sharedBy?.email" type="label" size="sm" class="select-none">
          {{ sharedBy.email }}
        </BaseText>
        <BaseText v-else size="sm" class="select-none text-neutral-alpha-700">
          A user
        </BaseText>
        <BaseText size="sm" class="text-neutral-alpha-700">
          shared this {{ shareType }} with you.
        </BaseText>
        <div class="flex gap-0.5 ml-3" v-if="isSameTeam">
          <div class="flex items-center gap-x-1 bg-neutral-alpha-200 p-1 pr-2 rounded-l-md">
            <img class="w-5 h-5" src="../../../assets/icons/information-icon.svg" />
            <BaseText size="sm">
              You can customize this page
            </BaseText>
          </div>
          <div
            class="rounded-r-md pr-2.5 pl-1.5 py-1 flex gap-1 items-center bg-neutral-alpha-200 hover:bg-neutral-alpha-300 transition-all cursor-pointer"
            @click="redirectToEdit">
            <EditIcon :width="20" :height="20" />
            <BaseText type="label" size="sm">
              Edit
            </BaseText>
          </div>
        </div>
      </div>
      <!-- Current user Info -->
      <div class="flex gap-x-3 items-center">
        <div class="flex items-center gap-1">
          <BaseText size="sm">
            Created with
          </BaseText>
          <a href="https://foreplay.co" rel="nofollow" target="_blank" class="hover:opacity-75"
            style="transition: 200ms">
            <img src="../../../assets/images/brief-foreplay-icon.svg" alt="" class="filter invert h-7">
          </a>
        </div>
        <div class="text-neutral-alpha-300 w-min text-center">
          |
        </div>
        <div v-if="Object.keys(getUser).length > 0" @click="showUserPopover = !showUserPopover"
          v-on-clickaway="() => { showUserPopover = false }" class="relative z-50 ">
          <div
            class="flex gap-2 items-center p-2 rounded-md hover:bg-neutral-alpha-300 cursor-pointer transition-colors"
            :class="`${showUserPopover && 'bg-neutral-alpha-300'}`">
            <img :src="getUser?.avatar || fallbackAvatar" class="w-5 h-5 rounded-full">
            <BaseText v-if="getUser?.name" type="label" size="sm" class="select-none">
              {{ getUser.name }}
            </BaseText>
            <BaseText v-else-if="getUser?.first_name" type="label" size="sm" class="select-none">
              {{ getUser.first_name }}&nbsp;{{ getUser?.last_name }}
            </BaseText>
            <BaseText v-else type="label" size="sm" class="select-none">
              {{ getUser.email }}
            </BaseText>
            <div class="transform transition-transform duration-200"
              :style="showUserPopover ? 'transform: scale(1, -1)' : ''">
              <ChevronIcon className="text-white" />
            </div>
          </div>
          <transition name="fade">
            <div v-if="showUserPopover"
              class="absolute w-full min-w-max right-0  p-1  shadow-md profile-popover  rounded-lg flex flex-col gap-2">
              <div @click="redirectToDashboard"
                class="flex rounded-md gap-2 items-center cursor-pointer p-1.5 hover:bg-neutral-alpha-100 transition-all">
                <ForeplayIcon className="stroke-white" />
                <BaseText type="label" size="sm">Open Foreplay</BaseText>
                <ChevronIcon className="transform -rotate-90" />
              </div>
              <div class="w-full bg-neutral-alpha-200 h-0.5" />
              <div @click="showDuplicateModal = true"
                class="flex rounded-md gap-2 items-center cursor-pointer p-1.5 hover:bg-neutral-alpha-100 transition-all">
                <CopyBoardIcon />
                <BaseText type="label" size="sm">Duplicate Board</BaseText>
              </div>
              <div @click="handleLogout"
                class="flex rounded-md gap-2 items-center cursor-pointer p-1.5 hover:bg-neutral-alpha-100 transition-all">
                <LogoutIcon className="stroke-white" />
                <BaseText type="label" size="sm">Logout</BaseText>
              </div>
            </div>
          </transition>
        </div>
        <div v-else class="flex items-center gap-2">
          <BaseButton @click="redirectTo('/login')" class="hover:bg-neutral-alpha-300">
            <div class="flex items-center gap-2">
              <img class="w-5 h-5" src="../../../assets/icons/sign-in-redirect.svg">
              <BaseText type="label" size="sm">
                Sign in
              </BaseText>
            </div>
          </BaseButton>
          <BaseButton class="rounded-md border border-white hover:bg-neutral-alpha-300" @click="redirectTo('/sign-up')">
            <div class="flex gap-2 items-center">
              <BaseText type="label" size="sm">
                Start your Free Trial
              </BaseText>
              <img src="../../../assets/icons/chevron-right-small.svg" class="w-5 h-5" />
            </div>
          </BaseButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WhiteLabelGradient from './WhiteLabelGradient.vue'
import { mixin as clickaway } from 'vue-clickaway2'
import { mapActions, mapGetters } from 'vuex';

// Icons
import ChevronIcon from '../../globals/Icons/ChevronIcon.vue';
import EditIcon from '../../globals/Icons/EditIcon.vue';
import ForeplayIcon from '../../globals/Icons/ForeplayIcon.vue';
import LogoutIcon from '../../globals/Icons/LogoutIcon.vue';
import CopyBoardIcon from '../../globals/Icons/CopyBoardIcon.vue';
import DuplicateBoardModal from '../../boards/DuplicateBoardModal.vue';

export default {
  name: 'WhiteLabelHeader',
  mixins: [clickaway],
  components: {
    WhiteLabelGradient,
    DuplicateBoardModal,
    EditIcon,
    ForeplayIcon,
    ChevronIcon,
    LogoutIcon,
    CopyBoardIcon
  },
  props: {
    boardTeam: {
      type: Object
    },
    showDefault: {
      type: Boolean,
      default: false
    },
    sharedBy: {
      type: Object
    },
    board: {
      type: Object
    },
    ads: {
      type: Array
    }
  },
  data() {
    return {
      fallbackAvatar: require('../../../assets/images/defaultProfileImage.webp'),

      showUserPopover: false,
      showDuplicateModal: false,

      shareType: 'board'
    }
  },
  computed: {
    ...mapGetters('AuthModule', ['getTeam', 'getUser']),
    isSameTeam() {
      const board = this.$props.board;
      // If no team data, return false
      if (!this.getTeam?.id || !board?.teamId) return false;
      return this.getTeam?.id === board.teamId;
    }
  },
  mounted() {
    const path = this.$route.path;
    const type = path.match(/\/(ads|boards)\//)
    if (!type[1]) this.shareType = 'board'

    this.shareType = type[1] === 'ads' ? 'ad' : 'board'
  },
  methods: {
    ...mapActions('AuthModule', ['logoutUser']),
    redirectToDashboard() {
      const url = "https://app.foreplay.co/dashboard"
      window.open(url, "_blank");
    },
    openCompanyURL() {
      const url = this.$props.boardTeam.whiteLabel.companyURL;
      if (url) {
        window.open(url.startsWith('http') ? url : `http://${url}`, "_blank");
      } else {
        console.error("Invalid URL:", url);
      }
    },
    redirectTo(path) {
      this.$router.push(path)
    },
    redirectToEdit() {
      this.$router.push({ path: '/account', query: { openLabelEdit: true } })
    },
    handleLogout() {
      this.logoutUser();
    },
  }
}
</script>

<style scoped lang="css">
.profile-popover {
  background-color: #131119;

  bottom: -140px
}

.fade-enter-active,
.fade-leave-active {
  transition: all 150ms;
}

.fade-enter,
.fade-leave-to .fade-leave-active {
  opacity: 0;
  transform: translateY(-1rem);
}
</style>
